<template>
    <v-container v-if="$store.state.user">

        <!-- Page Title-->
        <v-row class="text-center mt-10">
            <v-col class="mb-2">
                <h3 class="display-2 font-weight-bold mb-3">Add Assessment</h3>
            </v-col>   
        </v-row>


        <!-- Start of form for user input -->
        <v-row class="justify-center">
            <v-col class="mt-16" cols="4">
                <v-form ref="form" v-model="valid">
                
                    <!-- Assessment Type Drop-down selection -->
                    <v-select v-model="assessment_type_id" :items="assessmentTypes" item-text="type"
                        item-value="id" :rules="assessmentRules" label="Assessment Type" required>
                    </v-select>

                    <!-- Amount Input-->
                    <v-text-field :rules="amountRules" label="Amount" prepend-icon="mdi-currency-usd" 
                        v-model="amount" required></v-text-field>

                    <!-- Date Picker -->
                    <v-menu ref="menu" v-model="menu"
                        :close-on-content-click="false"
                        :return-value.sync="date"
                        transition="scale-transition" offset-y min-width="auto">

                        <template v-slot:activator="{ on, attrs }">
                            <v-text-field
                                v-model="date" label="Date" prepend-icon="mdi-calendar"
                                v-bind="attrs" v-on="on">
                            </v-text-field>
                        </template>

                        <v-date-picker v-model="date" no-title scrollable>
                            <v-spacer></v-spacer>
                            <v-btn text color="primary" @click="menu = false">Cancel</v-btn>
                            <v-btn text color="primary" @click="$refs.menu.save(date)">OK</v-btn>
                        </v-date-picker>
                    </v-menu>

                    <!-- User input message box-->
                    <v-textarea label="Notes" prepend-icon="mdi-message-bulleted" v-model="notes"></v-textarea>

                    <!-- Form Submit button -->
                    <v-btn :disabled="!valid" color="success" class="mr-4" @click="validate">Submit</v-btn>

                    <!-- Form Clear button -->
                    <v-btn color="error" class="mr-4" @click="reset">Clear Form</v-btn>

                </v-form>
            </v-col> 
        </v-row>
    </v-container>
</template>

<script>
    import AssociationService from '@/services/AssociationsService'
    import PaymentService from '@/services/PaymentService'

    export default {
        name: 'addAssessment',

        data () {
            return {
                valid: false,
                assessment_type_id: null,
                amount: '',
                notes: '',

                // Custom rules to verify user inputs are valid
                assessmentTypes: [],
                assessmentRules: [
                    v => !!v || 'Assessment Type is required',
                ],
                amountRules: [
                    v => !!v || 'Amount is required',
                    v => /[0-9]*\.[0-9]{2,2}$/.test(v) || 'Must be in the form of a two-digit decimal.',
                ],

                // Variables below used for the date picker Vuetify component
                date: (new Date(Date.now() - (new Date()).getTimezoneOffset() * 60000)).toISOString().substr(0, 10),
                menu: false,
                modal: false,
                menu2: false,
            }
        },

        // Vue async mount function which hits API EP for data 
        async mounted() {
            PaymentService.getAllAssessmentTypes()
            .then(result => {
                result.data.forEach(element => {
                    this.assessmentTypes.push({
                        id: element.id,
                        type: element.name
                    })
                })
            })
        },

        methods: {
            
            // Validate user input based on custom rules above
            validate () {
                this.$refs.form.validate()

                try {
                    AssociationService.addNewAssessment({
                        assessment_type_id: this.assessment_type_id,
                        date: this.date,
                        note: this.notes,
                        amount: parseFloat(this.amount),
                        person_id: this.$store.state.personID
                    })

                    this.$router.push({ path: `/associations/peopleDetails/${this.$store.state.personID}`})

                } catch (error) {
                    this.error = error.response.data.error
                }

            },
            // Reset form (clears all user input)
            reset () {
                this.$refs.form.reset()
            },

        },
    }
</script>